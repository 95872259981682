import React from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Maintain from "./pages/Maintain";
import "./styles/GlobalStyles.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Maintain />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

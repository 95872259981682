import React, { useEffect, useRef, useState } from "react";

import img1 from "../../assets/images/home-about.jpg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as VimaLogo } from "../../assets/images/vima.svg";

import { AiOutlineDown } from "react-icons/ai";
import { IoIosMail } from "react-icons/io";
import { BsTelephoneFill, BsInstagram, BsWhatsapp } from "react-icons/bs";
import "./style.scss";

const logoVaients = {
  hidden: { y: 0, scale: 1 },
  show: {
    y: "-14rem",
    scale: 0.3,
    transition: {
      duration: 0.5,
      delay: 1,
    },
  },
};
const infoVaients = {
  hidden: { y: 0, scale: 1 },
  show: {
    y: "-10rem",
    transition: {
      duration: 0.5,
      delay: 1.5,
    },
  },
};

const Maintain = () => {
  const contact = useRef(null);
  const [logoMove, setLogoMove] = useState(false);
  const [infoMove, setInfoMove] = useState(false);

  const scrollTo = (ref) =>
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });

  useEffect(() => {
    let timer1 = setTimeout(() => setLogoMove(true), 1000);
    let timer2 = setTimeout(() => setInfoMove(true), 1250);
    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <main id="maintain">
      <section className="maintain-hero">
        <img src={img1} alt="" />

        <div
          variants={logoVaients}
          initial="hidden"
          animate="show"
          className={`logo ${logoMove ? "fixed" : ""}`}
        >
          <Logo />
          <h1>custom build</h1>
        </div>
        <div
          variants={infoVaients}
          initial="hidden"
          animate="show"
          className={`info ${infoMove ? "fixed" : ""}`}
        >
          <h2>we'll be right back!</h2>
          <p>
            Sorry, the website is temporarily closed for construction!
            <br /> For business inquiries, do not hesitate to contact us through
            the links below. <br /> We will be back soon…
          </p>
        </div>
        <div className="to-contact" onClick={() => scrollTo(contact)}>
          <h6>contact us</h6>
          <AiOutlineDown />
        </div>
      </section>
      <section ref={contact} className="maintain-contact">
        <div className="container">
          <h2>contact us</h2>
          <div className="contact-items">
            <a href="mailto:k1@deilamibuild.ca" className="contact-item">
              <IoIosMail style={{ padding: "5px" }} />
              <p>k1@deilamibuild.ca</p>
            </a>
            <a href="tel:+14165603333" className="contact-item">
              <BsTelephoneFill />
              <p>+1 (416) 560 3333</p>
            </a>
            <a
              href="https://www.instagram.com/deilamicustombuild/?hl=en"
              className="contact-item"
            >
              <BsInstagram />
              <p>deilamicustombuild</p>
            </a>
            <a href="tel:+14165603333" className="contact-item">
              <BsWhatsapp />
              <p>+1 (416) 560 3333</p>
            </a>
          </div>
        </div>
        <a className="creator" href="https://www.instagram.com/vimatech_/">
          <VimaLogo />
        </a>
      </section>
    </main>
  );
};

export default Maintain;
